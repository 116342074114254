<template>
    <svg :width="width" :height="height" :fill="fill" class="bi bi-arrow-right-short" viewBox="0 0 16 16">
        <path fill-rule="evenodd"
            d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8" />
    </svg>
</template>

<script>
export default {
    name: "ArrowRightShortIcon",
    props: {
        width: {
            type: [Number, String],
            default: 18
        },
        height: {
            type: [Number, String],
            default: 18
        },
        fill: {
            type: String,
            default: 'currentColor'
        }
    }
}
</script>