<template>
  <main class="container">
    <router-view />
  </main>
</template>


<script>

export default {
  name: 'App',
}
</script>

<style>
@import './assets/styles/base.css';
</style>